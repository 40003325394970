import { type HttpClient } from '@/application/interfaces/http-client';

import { addItemToWishlist } from './add-item-to-wishlist';
import { createWishlist } from './create-wishlist';
import { getWishlistItemsIds } from './get-wishlist-items-ids';
import { getWishlists } from './get-wishlists';
import { removeItemFromWishlist } from './remove-item-from-wishlist';

const WishlistService = (httpClient: HttpClient.Instance) => ({
  getWishlists: getWishlists(httpClient),
  addItemToWishlist: addItemToWishlist(httpClient),
  createWishlist: createWishlist(httpClient),
  getWishlistItemsIds: getWishlistItemsIds(httpClient),
  removeItemFromWishlist: removeItemFromWishlist(httpClient),
});

export default WishlistService;

import { type ServiceMethod } from '@/application/interfaces/service';

import { type Metadata } from '../../model';

export type Params = {
  slug: string;
};

export type Response = {
  data: Metadata & { id: string };
};

const getMetadata: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params>(
      '/api/v3/modularContents/{slug}/metadata',
      {
        ...options,
        params: {
          slug: data.slug,
        },
      }
    );

    return body;
  };

export default getMetadata;

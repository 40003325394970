import { type ServiceMethod } from '@/application/interfaces/service';

import { type CategoriesTree } from './model';

export type Response = {
  data: {
    results: CategoriesTree;
  };
};

const getCategoriesTree: ServiceMethod<Response> =
  (httpClient) => async (options) => {
    const { body } = await httpClient.get<Response, undefined, undefined>(
      `/api/v3/categories/tree`,
      options
    );

    return body;
  };

export default getCategoriesTree;

import { isBrowser } from '@backyard-ui/utils';

import { IS_ON_MOBILE_APP } from '../constants/cookies';
import { cookies } from '../cookies';

export const getIsOnMobileAppClient = () => {
  if (!isBrowser) {
    throw new Error('This function should be used only on the client side.');
  }
  const hasMobileAppCookie = cookies.client.has(IS_ON_MOBILE_APP);

  return hasMobileAppCookie;
};

export const getIsOnMobileAppServer = async () => {
  if (isBrowser) {
    throw new Error('This function should be used only on the server side.');
  }
  const hasMobileAppCookie = await cookies.server.has(IS_ON_MOBILE_APP);

  return hasMobileAppCookie;
};

import { type ServiceMethodNew } from '@/application/interfaces/service';

type Data = {
  url: string;
};

const tracking: ServiceMethodNew<any, Data> =
  (httpClient) => async (data, options) => {
    return await httpClient.get(data.url, options);
  };

export default tracking;

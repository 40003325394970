import { type ServiceMethod } from '@/application/interfaces/service';
import { type AddedService } from '@/infra/services/checkout/model';

export type Body = {
  override?: boolean;
  services: {
    id: number;
    quantity: number;
  }[];
};

export type Response = {
  data: AddedService[];
};

const addServicesToCart: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { services, override } = data;

    const { body: response } = await httpClient.post<Response, Body>(
      '/api/v3/carts/services',
      {
        ...options,
        body: {
          services,
          override,
        },
      }
    );

    return response;
  };

export default addServicesToCart;

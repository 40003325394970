import { type ServiceMethod } from '@/application/interfaces/service';

import { type PageType } from '../get-modules';

export type Params = {
  slug: string;
  pageType: PageType;
};

export type Response = {
  data: string[];
};

const getRecommendedProductsPlacements: ServiceMethod<Response, Params> =
  (httpClient) =>
  async ({ slug, pageType }, options) => {
    const { body } = await httpClient.get<Response, Params, undefined>(
      `/api/v3/{pageType}/{slug}/placements`,
      {
        ...options,
        params: {
          slug,
          pageType,
        },
      }
    );

    return body;
  };

export default getRecommendedProductsPlacements;

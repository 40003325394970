import { type HttpClient } from '@/application/interfaces/http-client';

import { getBreadcrumb } from './get-breadcrumb';
import getCategoriesTree from './get-categories-tree';
import { getDetails } from './get-details';
import { getMetadata } from './get-metadata';
import getSubcategoryChildren from './get-subcategory-children';

const CategoriesService = (httpClient: HttpClient.Instance) => ({
  getBreadcrumb: getBreadcrumb(httpClient),
  getCategoriesTree: getCategoriesTree(httpClient),
  getDetails: getDetails(httpClient),
  getMetadata: getMetadata(httpClient),
  getSubcategoryChildren: getSubcategoryChildren(httpClient),
});

export default CategoriesService;

import { type ServiceMethod } from '@/application/interfaces/service';

import { type Region, type Coordinates, type ZipCode } from './model';

export type Response = {
  data: {
    region: string;
    regionName: string;
    zipCode?: string;
    cartId?: string;
    cartItemsQuantity?: number;
    cacheControlKey?: string;
  };
};

const endpoint = '/api/v3/context/user-context';

export const postUserContextByCoordinates: ServiceMethod<
  Response,
  Coordinates
> = (httpClient) => async (data, options) => {
  const { body } = await httpClient.post<Response, Coordinates>(endpoint, {
    ...options,
    body: data,
  });

  return body;
};

export const postUserContextByRegion: ServiceMethod<Response, Region> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<Response, Region>(endpoint, {
      ...options,
      body: data,
    });

    return body;
  };

export const postUserContextByZipCode: ServiceMethod<Response, ZipCode> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<Response, ZipCode>(endpoint, {
      ...options,
      body: data,
    });

    return body;
  };

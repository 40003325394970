import { type HttpClient } from '@/application/interfaces/http-client';

import { getRatings } from './get-ratings';
import { getReviews } from './get-reviews';
import { getSummary } from './get-summary';
import { postFeedback } from './post-feedback';

const ReviewService = (httpClient: HttpClient.Instance) => ({
  getRatings: getRatings(httpClient),
  getReviews: getReviews(httpClient),
  getSummary: getSummary(httpClient),
  postFeedback: postFeedback(httpClient),
});

export default ReviewService;

import { isBrowser } from '@backyard-ui/utils';

import { type HttpClient } from '@/application/interfaces/http-client';
import { PREVIEW } from '@/utils/constants/headers';
import { headers } from '@/utils/headers';

import { addSearchParam } from '../utils/add-search-param';

const checkHasPreviewTrueQuery = async () => {
  const previewQuery = 'preview=true';

  return isBrowser
    ? window.location.search.includes(previewQuery)
    : (await headers.get(PREVIEW)) === 'true';
};

export const addPreviewTrueQuery: HttpClient.RequestInterceptor = async (
  options
) => {
  const hasPreviewTrueQuery = await checkHasPreviewTrueQuery();

  if (!hasPreviewTrueQuery) {
    return options;
  }

  addSearchParam(options, 'preview', 'true');

  return options;
};

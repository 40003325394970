import { type HttpClient } from '@/application/interfaces/http-client';

import { addProductsToCart } from './add-products-to-cart';
import addServicesToCart from './add-services-to-cart';

const cartService = (httpClient: HttpClient.Instance) => ({
  addProductsToCart: addProductsToCart(httpClient),
  addServicesToCart: addServicesToCart(httpClient),
});

export default cartService;

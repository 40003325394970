import { type ServiceMethod } from '@/application/interfaces/service';

export type Content = {
  id: string;
  title?: string;
  url: string;
  image: {
    alt: string;
    url: string;
    size: {
      width?: number;
      height?: number;
    };
  };
  publishDate: string;
};

export type PageDetails = {
  id: string;
  title: string;
  description: string;
  contents?: Content[];
};

export type Response = {
  data: PageDetails;
};

export type Params = {
  slug: string;
};

const getDetails: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, undefined>(
      `/api/v3/categories/{slug}`,
      {
        ...options,
        params: {
          slug: data.slug,
        },
      }
    );

    return body;
  };

export default getDetails;

import { type ServiceMethod } from '@/application/interfaces/service';

import { type BaseParams, type ProductAds } from './model';

export type Body = BaseParams & {
  placements: {
    productsAds: { quantity: number; types: ['product'] };
  };
};

export type Response = {
  productsAds: ProductAds[];
};

const baseUrl = process.env.NEXT_PUBLIC_RETAIL_MEDIA_BASE_URL;
const publisherId = process.env.NEXT_PUBLIC_RETAIL_MEDIA_PUBLISHER_ID;

const getProductsAds: ServiceMethod<Response, BaseParams> =
  (httpClient) => async (params, options) => {
    const { context, session_id, user_id } = params;

    const { body: response } = await httpClient.post<Response, Body>(
      `${baseUrl}/v1/rma/${publisherId}`,
      {
        ...options,
        body: {
          context,
          session_id,
          user_id,
          placements: {
            productsAds: { quantity: 10, types: ['product'] },
          },
        },
      }
    );

    return response;
  };

export default getProductsAds;

import { type ServiceMethod } from '@/application/interfaces/service';

export type Response = {
  data: {
    enableBannerInstallApp: boolean;
    textFirstLineBanner: string;
    textSecondLineBanner: string;
    textButtonBanner: string;
    linkButtonBanner: string;
  };
};

const getBannerInstallApp: ServiceMethod<Response> =
  (httpClient) => async (options) => {
    const { body } = await httpClient.get<Response>(
      '/api/v3/app/banner-install-app',
      options
    );

    return body;
  };

export default getBannerInstallApp;

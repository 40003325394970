import { type HttpClient } from '@/application/interfaces/http-client';

import { getMetadata } from './get-metadata';
import { getModules } from './get-modules';
import getRecommendedProductsData from './get-recommended-products-data';
import { getRecommendedProductsPlacements } from './get-recommended-products-placements';
import getVouchersData from './get-vouchers-data/get-vouchers-data';

const ModularContentService = (httpClient: HttpClient.Instance) => ({
  getMetadata: getMetadata(httpClient),
  getModules: getModules(httpClient),
  getRecommendedProductsData: getRecommendedProductsData(httpClient),
  getRecommendedProductsPlacements:
    getRecommendedProductsPlacements(httpClient),
  getVouchersData: getVouchersData(httpClient),
});

export default ModularContentService;

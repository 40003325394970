import { type ServiceMethod } from '@/application/interfaces/service';

export type Params = {
  id: string | number;
  type: 'contents' | 'products';
};

const removeItemFromWishlist: ServiceMethod<void, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.delete<void, Params, undefined>(
      `/api/v3/wishlists/{type}/{id}`,
      {
        ...options,
        params: {
          id: data.id,
          type: data.type,
        },
      }
    );

    return body;
  };

export default removeItemFromWishlist;

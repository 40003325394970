import { type ServiceMethod } from '@/application/interfaces/service';

export type Region = {
  id: string;
  name: string;
  state: string;
};

export type GetRegionsReponse = {
  data: Region[];
};

const getRegions: ServiceMethod<GetRegionsReponse> =
  (httpClient) => async (options) => {
    const { body } = await httpClient.get<
      GetRegionsReponse,
      undefined,
      undefined
    >('/api/v3/regions', options);

    return body;
  };

export default getRegions;

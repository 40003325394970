import { type ServiceMethod } from '@/application/interfaces/service';
import { type Price, type ItemId } from '@/infra/services/catalog/model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: { id: number; name: string; price: Price }[];
};

const getOptionals: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/crosses/{itemId}/services',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return response;
  };

export default getOptionals;

import { type ServiceMethod } from '@/application/interfaces/service';

import {
  type ReviewId,
  type FeedbackType,
  type FeedbackVoteValue,
} from '../model';

export type Body = {
  reviewId: ReviewId;
  feedbackType: FeedbackType;
  voteValue?: FeedbackVoteValue;
};

export type Response = {
  data: string;
};

const postFeedback: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { feedbackType, reviewId, voteValue } = data;

    const { body: response } = await httpClient.post<Response, Body>(
      '/api/v3/reviews/feedback',
      {
        ...options,
        body: {
          feedbackType,
          reviewId,
          voteValue,
        },
      }
    );

    return response;
  };

export default postFeedback;

import { type ServiceMethod } from '@/application/interfaces/service';

export type Body = {
  email?: string;
  cep?: string;
};

const postNewsletterSubscription: ServiceMethod<void, Body> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<void, Body, undefined, undefined>(
      `/api/v3/newsletter`,
      {
        ...options,
        body: {
          ...(data.email ? { email: data.email } : {}),
          ...(data.cep ? { cep: data.cep } : {}),
        },
      }
    );

    return body;
  };

export default postNewsletterSubscription;

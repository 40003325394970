import QueryString, { type IStringifyOptions } from 'qs';

import { type HttpClient } from '@/application/interfaces/http-client';

const isTimeoutEnvSafe = !isNaN(
  Number(process.env.NEXT_PUBLIC_REQUESTS_TIMEOUT)
);

const DEFAULT_TIMEOUT = isTimeoutEnvSafe
  ? Number(process.env.NEXT_PUBLIC_REQUESTS_TIMEOUT)
  : 5000;

export class FetchHttpClient implements HttpClient.Instance {
  public errorReporter: HttpClient.ErrorReporter = null;
  public interceptors: HttpClient.Interceptors = {};

  constructor(
    private readonly defaultOptions: HttpClient.DefaultOptions = {
      baseUrl: '',
    }
  ) {}

  async get<
    R,
    P extends undefined | HttpClient.Params = undefined,
    S extends undefined | HttpClient.SearchParams = undefined
  >(
    url: string,
    options?: HttpClient.Options<undefined, P, S>
  ): Promise<HttpClient.Response<R>> {
    const requestOptions = {
      method: 'GET',
      ...options,
    };

    return this.sendRequest<R>(url, requestOptions);
  }

  async post<
    R,
    B,
    P extends undefined | HttpClient.Params = undefined,
    S extends undefined | HttpClient.SearchParams = undefined
  >(
    url: string,
    options?: HttpClient.Options<B, P, S>
  ): Promise<HttpClient.Response<R>> {
    const requestOptions = {
      method: 'POST',
      ...options,
    };

    return this.sendRequest<R>(url, requestOptions);
  }

  async put<
    R,
    B,
    P extends undefined | HttpClient.Params = undefined,
    S extends undefined | HttpClient.SearchParams = undefined
  >(
    url: string,
    options?: HttpClient.Options<B, P, S>
  ): Promise<HttpClient.Response<R>> {
    const requestOptions = {
      method: 'PUT',
      ...options,
    };

    return this.sendRequest<R>(url, requestOptions);
  }

  async patch<
    R,
    B,
    P extends undefined | HttpClient.Params = undefined,
    S extends undefined | HttpClient.SearchParams = undefined
  >(
    url: string,
    options?: HttpClient.Options<B, P, S>
  ): Promise<HttpClient.Response<R>> {
    const requestOptions = {
      method: 'PATCH',
      ...options,
    };

    return this.sendRequest<R>(url, requestOptions);
  }

  async delete<
    R,
    P extends undefined | HttpClient.Params = undefined,
    S extends undefined | HttpClient.SearchParams = undefined
  >(
    url: string,
    options?: HttpClient.Options<undefined, P, S>
  ): Promise<HttpClient.Response<R>> {
    const requestOptions = {
      method: 'DELETE',
      ...options,
    };

    return this.sendRequest<R>(url, requestOptions);
  }

  private buildUrl = (
    url: string,
    options: Partial<
      HttpClient.Options<any, HttpClient.Params, HttpClient.SearchParams>
    >
  ) => {
    const baseUrl = options.baseUrl?.endsWith('/')
      ? options.baseUrl.slice(0, -1)
      : options.baseUrl;

    let fullURL = url.startsWith('/') ? baseUrl + url : url;

    fullURL = url.endsWith('/') ? fullURL.slice(0, -1) : fullURL;

    if (options.params) {
      for (let [key, value] of Object.entries(options.params)) {
        fullURL = fullURL.replace(`{${key}}`, String(value));
      }
    }

    if (options.searchParams) {
      const arrayFormats: Record<
        HttpClient.SearchParamsArrayFormat,
        IStringifyOptions['arrayFormat']
      > = {
        indexes: 'indices',
        comma: 'comma',
      };

      const searchParams =
        options.searchParams instanceof URLSearchParams
          ? QueryString.parse(options.searchParams.toString())
          : options.searchParams;

      const queryString = QueryString.stringify(searchParams, {
        arrayFormat: arrayFormats[options.searchParamsArrayFormat ?? 'indexes'],
      });

      fullURL += `?${queryString}`;
    }

    return fullURL;
  };

  private async sendRequest<R>(
    url: string,
    options: Omit<RequestInit, 'body' | 'params' | 'searchParams'> &
      Partial<HttpClient.Options<BodyInit>>
  ): Promise<HttpClient.Response<R>> {
    const { timeout, signal, ...restOptions } = options;

    try {
      const hasHeaders = this.defaultOptions.headers || options.headers;
      const headers = hasHeaders
        ? { ...this.defaultOptions.headers, ...options.headers }
        : undefined;

      let fullOptions: Omit<typeof options, 'timeout'> = {
        ...this.defaultOptions,
        ...restOptions,
        ...(headers ? { headers } : {}),
        // @ts-ignore A versão que usamos do TS ainda não tem essa tipagem
        signal: AbortSignal.any([
          ...(options.signal ? [options.signal] : []),
          AbortSignal.timeout(timeout ?? DEFAULT_TIMEOUT),
        ]),
      };

      if (this.interceptors.request) {
        fullOptions = await this.interceptors.request(fullOptions);
      }

      const fullUrl = this.buildUrl(url, fullOptions);

      if (options.method !== 'GET' && options.method !== 'DELETE') {
        fullOptions.body = JSON.stringify(fullOptions.body);
      }

      const fetchResponse = await fetch(fullUrl, fullOptions);

      if (!fetchResponse.ok || fetchResponse.status >= 300) {
        throw fetchResponse;
      }

      const response = {
        body: await fetchResponse.json().catch(() => null),
        headers: fetchResponse.headers,
        status: fetchResponse.status,
        statusText: fetchResponse.statusText,
        defaultResponse: fetchResponse,
      };

      const processedResponse = this.interceptors.response
        ? await this.interceptors.response(response)
        : response;

      return processedResponse;
    } catch (error) {
      if (this.errorReporter) {
        this.errorReporter(error, {
          url,
          defaultOptions: this.defaultOptions,
          options,
        });
      }

      return Promise.reject(error);
    }
  }
}

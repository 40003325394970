import { type Response } from './get-product-info';

export const getProductInfoFixture: Response = {
  data: {
    product: {
      _id: 90579741,
      brand: 'Einhell',
      characteristics: [{ name: 'characteristics 1', value: '1' }],
      description: 'Instalação de AR',
      disclaimer: 'Preparação de toda infra e instalação',
      discount: {
        discountPercentage: 1,
        discountType: '',
        paymentMethodsDiscount: '1',
      },
      fullDescription: 'Preparação de toda infra e instalação',
      name: 'Instalação',
      short_name: 'Instalação',
      price: {
        from: 10,
        to: 9,
      },
      visualMedias: {
        medias: [
          {
            main: 'https://cdn.leroymerlin.com.br/products/piso_laminado_click_elmo_ravena_136x21,7cm_m2_artens_89238114_e079_300x300.jpg',
            alt: '',
          },
        ],
      },
      ean: '4006825618709',
      isAvailableOnEcommerce: false,
      isExclusiveOnEcommerce: false,
      url: '',
      unit: '',
    },
    currentSeller: {
      id: '',
      selected: true,
      toPrice: 339,
      discountPercentage: 6,
      installmentsValue: '120,21',
      installmentsAmount: 3,
      installmentsTotalValue: '360,64',
      brandedInstallmentsValue: '36,06',
      brandedInstallmentsAmount: 10,
      brandedInstallmentsTotalValue: '360,64',
      price: {
        to: {
          integers: '339',
          decimals: '00',
        },
        from: {
          integers: '360',
          decimals: '64',
        },
        regionPrice: null,
        suggested: null,
        installmentPrice: null,
      },
      shop: {
        id: 'leroy',
        name: 'Leroy Merlin',
        logo: 'https://example.com.br/assets/images/logo-leroy-merlin.svg',
      },
      limitQuantity: null,
      skuInstance: null,
      paymentMethodsDiscount: 'à vista',
      discountType: 'payment-method',
      isFreeShipping: true,
      isOutlet: false,
      purchaseButtons: {
        ecommerce: true,
        pickupInStore: true,
        assistedSale: false,
      },
      consultButtons: {
        ecommerceStock: true,
        assistedSaleStock: false,
        batchSearch: false,
      },
      isPrioritizedInTheBuyBox: null,
    },
  },
};

import { type ServiceMethod } from '@/application/interfaces/service';

import { type RemoteVoucherDetails } from './model';

export type Response = RemoteVoucherDetails;

export type Params = {
  voucherId: string;
};

const getVoucherModalData: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params>(
      `/api/v2/loyaltyProgram/vouchers/{voucherId}`,
      {
        ...options,
        params: {
          voucherId: data.voucherId,
        },
      }
    );

    return body;
  };

export default getVoucherModalData;

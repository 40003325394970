import { type ServiceMethod } from '@/application/interfaces/service';

export interface Meta {
  page: number;
  perPage: number;
  pages: number;
  count: number;
}

export interface Wishlist {
  id: string;
  name: string;
  slug: string;
  pictures?: {
    list: string;
    mosaic: string[];
  };
  contents: number;
  products: number;
}

export interface Response {
  data: Wishlist[];
  meta: Meta;
}

type SearchParams = {
  page?: number;
  perPage?: number;
};

const getWishlists: ServiceMethod<Response, SearchParams> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, undefined, SearchParams>(
      `/api/v3/wishlists`,
      {
        ...options,
        searchParams: {
          page: data.page,
          perPage: data.perPage,
        },
      }
    );

    return body;
  };

export default getWishlists;

import { type ServiceMethod } from '@/application/interfaces/service';

import { type ModularContent } from '../model';

export type PageType = 'categories' | 'modularContents';

export type Params = {
  slug: string;
  pageType: PageType;
};

export type SearchParams = {
  page: number;
};

export type Response = {
  meta: {
    page: number;
    perPage: number;
    pages: number;
    count: number;
  };
  data: {
    results: ModularContent.Module[];
    title: string;
  };
};

type Data = Params & SearchParams;

const getModules: ServiceMethod<Response, Data> =
  (httpClient) =>
  async ({ page, pageType, slug }, options) => {
    const { body } = await httpClient.get<Response, Params, SearchParams>(
      `/api/v3/{pageType}/{slug}/modules`,
      {
        ...options,
        searchParams: {
          page,
        },
        params: {
          slug,
          pageType,
        },
      }
    );

    return body;
  };

export default getModules;

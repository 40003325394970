import { type ServiceMethod } from '@/application/interfaces/service';

export type Response = {
  data: {
    name: string;
    url: string;
  }[];
};

export type Params = {
  slug: string;
};

const getBreadcrumb: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, undefined>(
      `/api/v3/categories/{slug}/breadcrumb`,
      {
        ...options,
        params: {
          slug: data.slug,
        },
      }
    );

    return body;
  };

export default getBreadcrumb;

import { type HttpClient } from '@/application/interfaces/http-client';

type Options = Parameters<HttpClient.RequestInterceptor>[0];

export function addSearchParam(options: Options, key: string, value: any) {
  options.searchParams = options.searchParams ?? {};

  if (options.searchParams instanceof URLSearchParams) {
    options.searchParams.set(key, value);
  } else {
    options.searchParams[key] = value;
  }
}

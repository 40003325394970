import { type ServiceMethodNew } from '@/application/interfaces/service';

import {
  type RichRelevanceOptions,
  type Placement,
  type UserData,
} from './model';

export type Response = {
  placements: Placement[];
  status: string;
  rcs?: string;
  errormessage?: string;
};

export type SearchParams = {
  apiKey: string;
  apiClientKey: string;
  placements?: string;
  rcs?: string;
  rid?: string;
  sessionId?: string;
  userId?: string;
} & RichRelevanceOptions;

export type Data = {
  userData: UserData;
  placements?: string;
  rcs?: string;
  options?: RichRelevanceOptions;
};

const apiKey = process.env.NEXT_PUBLIC_RICH_RELEVANCE_API_KEY;
const apiClientKey = process.env.NEXT_PUBLIC_RICH_RELEVANCE_CLIENT_ID;
const baseUrl = process.env.NEXT_PUBLIC_RICH_RELEVANCE_BASE_URL;

const getProductsByPlacements: ServiceMethodNew<Response, Data> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, undefined, SearchParams>(
      `${baseUrl}api/rrPlatform/recsForPlacements`,
      {
        ...options,
        searchParams: {
          apiKey,
          apiClientKey,
          placements: data.placements,
          rcs: data.rcs,
          rid: data.userData.region,
          sessionId: data.userData.sessionId,
          userId: data.userData.userId,
          ...data.options,
        },
      }
    );

    if (body.status === 'error') {
      throw new Error(body.errormessage);
    }

    return body;
  };

export default getProductsByPlacements;

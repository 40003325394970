import { type ServiceMethod } from '@/application/interfaces/service';
import {
  type ItemId,
  type PaymentMethods,
} from '@/infra/services/catalog/model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: PaymentMethods[];
};

const getPaymentMethods: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/products/{itemId}/payment-methods',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return response;
  };

export default getPaymentMethods;

import { type ServiceMethod } from '@/application/interfaces/service';

import { type PageType } from '../get-modules';
import { type ModularContent } from '../model';

export type SearchParams = {
  userId?: string;
};

export type Params = {
  slug?: string;
  moduleId?: string;
  pageType: PageType;
};

type Data = SearchParams & Params;

export type RemoteVoucherBasicData = ModularContent.VouchersData;

export type Response = {
  data: ModularContent.VouchersData[];
};

const getVouchersData: ServiceMethod<Response, Data> =
  (httpClient) =>
  async ({ pageType, slug, moduleId, userId }, options) => {
    const { body } = await httpClient.get<Response, Params, SearchParams>(
      '/api/v3/{pageType}/vouchers/{slug}/{moduleId}',
      {
        ...options,
        params: {
          slug,
          moduleId,
          pageType,
        },
        searchParams: { userId },
      }
    );

    return body;
  };

export default getVouchersData;

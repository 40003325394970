import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';

import { type Review, type Distribution, type Statistics } from '../model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: {
    badRate?: Review;
    goodRate?: Review;
    generalScores?: {
      distribution: Distribution[];
      statistics: Statistics;
    };
  };
};

const getSummary: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/reviews/{itemId}/summary',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return response;
  };

export default getSummary;

import { type HttpClient } from '@/application/interfaces/http-client';

import getProductsByPlacements from './get-products-by-placements';
import tracking from './tracking';

const RichRelevanceService = (httpClient: HttpClient.Instance) => ({
  getProductsByPlacements: getProductsByPlacements(httpClient),
  tracking: tracking(httpClient),
});

export default RichRelevanceService;

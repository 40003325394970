import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';

import { type Review, type Order } from '../model';

export type Params = {
  itemId: ItemId;
};

export type SearchParams = {
  page: number;
  order: Order;
};

export type Response = {
  data: {
    page: number;
    totalPages: number;
    totalReviews: number;
    reviews: Review[];
  };
};

type Data = Params & SearchParams;

const getReviews: ServiceMethod<Response, Data> =
  (httpClient) => async (data, options) => {
    const { itemId, order, page } = data;

    const { body: response } = await httpClient.get<
      Response,
      Params,
      SearchParams
    >('/api/v3/reviews/{itemId}', {
      ...options,
      searchParams: {
        order,
        page,
      },
      params: {
        itemId,
      },
    });

    return response;
  };

export default getReviews;

import { type HttpClient } from '@/application/interfaces/http-client';

import getLocationByZipcode from './get-location-by-zipcode';
import getLocationsByCityName from './get-locations-by-city-name';

const LocationService = (httpClient: HttpClient.Instance) => ({
  getLocationsByCityName: getLocationsByCityName(httpClient),
  getLocationByZipcode: getLocationByZipcode(httpClient),
});

export default LocationService;

import { type ServiceMethod } from '@/application/interfaces/service';

export type Body = {
  voucherId: string;
  inhabitantId: string;
};

export type Response = {
  voucher: {
    activatedId: string;
    id: string;
  };
};

const postVoucherShare: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<Response, Body>(
      `/api/v2/loyaltyProgram/vouchers/tranfer`,
      {
        ...options,
        body: {
          voucherId: data.voucherId,
          inhabitantId: data.inhabitantId,
        },
      }
    );

    return body;
  };

export default postVoucherShare;

import { type HttpClient } from '@/application/interfaces/http-client';

import { getBreadcrumb } from './get-breadcumb';
import { getProductInfo } from './get-product-info';
import { getSellers } from './get-sellers';
import { getVariants } from './get-variants';

const ProductService = (httpClient: HttpClient.Instance) => ({
  getProductInfo: getProductInfo(httpClient),
  getBreadcrumb: getBreadcrumb(httpClient),
  getVariants: getVariants(httpClient),
  getSellers: getSellers(httpClient),
});

export default ProductService;

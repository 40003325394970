import type JSCookie from 'js-cookie';

import { type CookieOptions } from '../types';

interface NormalizedOptions extends Omit<CookieOptions, 'sameSite'> {
  sameSite?: JSCookie.CookieAttributes['sameSite'];
}

// it normalizes the Next.js cookie options to js-cookie options
export function normalizeOptions(options: CookieOptions): NormalizedOptions {
  const normalizedOptions = Object.assign({}, options) as NormalizedOptions;

  // Both maxAge and expires have the same goal, so we keep only one to avoid confusion
  // Also, js-cookie library doesn't accept maxAge
  if (normalizedOptions.maxAge) {
    // maxAge is set in seconds while expires is in miliseconds
    normalizedOptions.expires = normalizedOptions.maxAge * 1000;
    delete normalizedOptions.maxAge;
  }

  if (typeof options.sameSite === 'boolean') {
    normalizedOptions.sameSite = options.sameSite ? 'strict' : 'none';
  }

  return normalizedOptions;
}

import { type ServiceMethod } from '@/application/interfaces/service';

import { type ItemId, type Variants } from '../../model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: Variants[];
};

const getVariants: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/products/{itemId}/variants',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return response;
  };

export default getVariants;

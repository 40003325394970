import { type ServiceMethod } from '@/application/interfaces/service';

export type Body = {
  name: string;
  products?: Array<{
    id?: number;
  }>;
  contents?: Array<{
    id?: string;
  }>;
};

export type Response = {
  data: {
    id: string;
    name: string;
    slug: string;
  };
};

const createWishlist: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<
      Response,
      Body,
      undefined,
      undefined
    >('/api/v3/wishlists', {
      ...options,
      body: {
        name: data.name,
        ...(data.contents ? { contents: data.contents } : {}),
        ...(data.products ? { products: data.products } : {}),
      },
    });

    return body;
  };

export default createWishlist;

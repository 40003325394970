import { type ServiceMethod } from '@/application/interfaces/service';

import { type LoyaltyProfile } from './model';

export interface Response {
  data: LoyaltyProfile;
}

const getLoyaltyProfile: ServiceMethod<Response> =
  (httpClient) => async (options) => {
    const { body } = await httpClient.get<Response, undefined, undefined>(
      `/api/v2/loyaltyProgram/profile`,
      options
    );

    return body;
  };

export default getLoyaltyProfile;

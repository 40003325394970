import { isBrowser } from '@backyard-ui/utils';

import { type HttpClient } from '@/application/interfaces/http-client';
import { DEVICE_HEADER } from '@/utils/constants/headers';
import { getDeviceType } from '@/utils/device/device';
import {
  getIsOnMobileAppClient,
  getIsOnMobileAppServer,
} from '@/utils/get-is-on-mobile-app/get-is-on-mobile-app';
import { headers } from '@/utils/headers';

const allowedDevices = ['desktop', 'mobile', 'app'];

const getDeviceFromQuery = async () => {
  const device = isBrowser
    ? new URLSearchParams(window.location.search).get('device')
    : await headers.get(DEVICE_HEADER);

  return device && allowedDevices.includes(device) ? device : null;
};

export const addDeviceHeader: HttpClient.RequestInterceptor = async (
  options
) => {
  options.headers = options.headers ?? {};

  const deviceFromQuery = await getDeviceFromQuery();

  if (deviceFromQuery) {
    options.headers[DEVICE_HEADER] = deviceFromQuery;
    return options;
  }

  const isOnMobileApp = isBrowser
    ? getIsOnMobileAppClient()
    : await getIsOnMobileAppServer();

  if (isOnMobileApp) {
    options.headers[DEVICE_HEADER] = 'app';
    return options;
  }

  const device = await getDeviceType();

  options.headers[DEVICE_HEADER] = device;
  return options;
};

import { type ServiceMethod } from '@/application/interfaces/service';

import { type SubCategoryChild } from './model';

export type Response = {
  data: {
    results: SubCategoryChild[];
  };
};

export type Params = {
  subcategoryId: string;
};

const getSubcategoryChildren: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, undefined>(
      `/api/v3/categories/{subcategoryId}/children`,
      {
        ...options,
        params: {
          subcategoryId: data.subcategoryId,
        },
      }
    );

    return body;
  };

export default getSubcategoryChildren;

import { type HttpClient } from '@/application/interfaces/http-client';

import getCustomers from './get-customers';
import getVoucherModalData from './get-voucher-modal-data';
import postVoucherActivation from './post-voucher-activation';
import postVoucherShare from './post-voucher-share';

const VouchersService = (httpClient: HttpClient.Instance) => ({
  getVoucherModalData: getVoucherModalData(httpClient),
  postVoucherActivation: postVoucherActivation(httpClient),
  getCustomers: getCustomers(httpClient),
  postVoucherShare: postVoucherShare(httpClient),
});

export default VouchersService;

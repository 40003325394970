import { ErrorReporter } from '@/infra/monitoring/error-reporter';

import { requestInterceptor } from '../interceptors';

import { FetchHttpClient } from './fetch-http-client';

export const httpClient = new FetchHttpClient({
  baseUrl: process.env.NEXT_PUBLIC_ENDPOINT_LEROY,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json, text/plain, */*',
  },
});

httpClient.errorReporter = (error, context) => {
  ErrorReporter.report(error, context);
};

httpClient.interceptors = {
  request: requestInterceptor,
};

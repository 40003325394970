import { type ServiceMethod } from '@/application/interfaces/service';

import { type Location } from './model';

export type Response = Location[];

export type Params = {
  cityName: string;
};

const getLocationsByCityName: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, undefined>(
      `/api/boitata/v1/zipcode/city/{cityName}`,
      {
        ...options,
        params: {
          cityName: data.cityName,
        },
      }
    );

    return body;
  };

export default getLocationsByCityName;

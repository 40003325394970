const prefix = process.env.NEXT_PUBLIC_SESSION_COOKIE_PREFIX || '';

export const AB_TESTING = 'ab_testing';
export const API_TOKEN = 'api_token';
export const ASSISTED_SALE = 'assisted_sale';
export const ASSISTED_SALE_HOME = 'assisted_sale_home';
export const CACHE_CONTROL_KEY = `${prefix}cache-control-key`;
export const CART_ID = `${prefix}cartId`;
export const CART_ITEMS_QUANTITY = `${prefix}cart_items_quantity`;
export const EMPLOYEE_NOTIFICATION = 'employee-notification-dispatched';
export const IS_ON_MOBILE_APP = 'is_on_mobile_app';
export const LOCATION_SOURCE = 'location_source';
export const REGION = 'region';
export const REGION_NAME = 'region_name';
export const RICH_RELEVANCE_RCS = 'rr_rcs';
export const STORE = `${prefix}store`;
export const TOTEM = `leroymerlin_totem`;
export const USER_TRACKING_ID = 'user_tracking_id';
export const ZIP_CODE = 'zip_code';

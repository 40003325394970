import { type HttpClient } from '@/application/interfaces/http-client';

import getBreadcrumb from './get-breadcrumb';
import getOptionals from './get-optionals';
import getServiceInfo from './get-service-info';

const ServiceService = (httpClient: HttpClient.Instance) => ({
  getBreadcrumb: getBreadcrumb(httpClient),
  getOptionals: getOptionals(httpClient),
  getServiceInfo: getServiceInfo(httpClient),
});

export default ServiceService;

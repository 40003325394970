import { type ServiceMethod } from '@/application/interfaces/service';

import { type Location } from './model';

export type Response = Location;

export type Params = {
  zipcode: string;
};

const getLocationByZipcode: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.get<Response, Params, undefined>(
      `/api/boitata/v1/zipcode/{zipcode}`,
      {
        ...options,
        params: {
          zipcode: data.zipcode,
        },
      }
    );

    return body;
  };

export default getLocationByZipcode;

import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId, type Breadcrumb } from '@/infra/services/catalog/model';

export type Params = {
  itemId: ItemId;
};

export type Response = {
  data: Breadcrumb;
};

const getBreadcrumb: ServiceMethod<Response, Params> =
  (httpClient) => async (data, options) => {
    const { itemId } = data;

    const { body: response } = await httpClient.get<Response, Params>(
      '/api/v3/shelf/services/{itemId}/breadcrumb',
      {
        ...options,
        params: {
          itemId,
        },
      }
    );

    return response;
  };

export default getBreadcrumb;

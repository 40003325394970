import { type ServiceMethod } from '@/application/interfaces/service';

import { type RemoteVoucherDetails } from './model';

export type Body = {
  voucherId: RemoteVoucherDetails['id'];
  type: RemoteVoucherDetails['type'];
};

export type Response = {
  voucher: {
    activatedId: string;
    id: string;
  };
};

const PostVoucherActivation: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<Response, Body>(
      `/api/v2/loyaltyProgram/vouchers/activate`,
      {
        ...options,
        body: {
          voucherId: data.voucherId,
          type: data.type,
        },
      }
    );

    return body;
  };

export default PostVoucherActivation;

export const AB_TESTING_HEADER = 'X-AB-Testing';
export const ASSISTED_SALE_HEADER = 'X-Assisted-Sale';
export const AUTHORIZATION_HEADER = 'Authorization';
export const CART_ID_HEADER = 'X-Cart-Id';
export const CLOUDFLARE_LATITUDE_HEADER = 'cf-iplatitude';
export const CLOUDFLARE_LONGITUDE_HEADER = 'cf-iplongitude';
export const CONTEXT_FROM_HEADER = 'context_from';
export const DEVICE_HEADER = 'X-Device';
export const MOBILE_APP_HEADER = 'X-Mobile-App';
export const REGION_HEADER = 'X-Region';
export const STORE_HEADER = 'X-Store';
export const TOTEM_HEADER = 'X-Totem';
export const PREVIEW = 'X-Preview';

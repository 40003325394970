import { isBrowser } from '@backyard-ui/utils';
import parser from 'ua-parser-js';

import { headers } from '@/utils/headers';

export const getDeviceType = async () => {
  const userAgent = isBrowser
    ? navigator.userAgent
    : ((await headers.get('user-agent')) as string);

  const device = parser(userAgent).device.type;

  if (device === 'tablet' || device === 'mobile') {
    return 'mobile';
  }

  return 'desktop';
};

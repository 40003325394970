import { type ServiceMethod } from '@/application/interfaces/service';

type CustomerStatus =
  | 'Pendente'
  | 'Ativo'
  | 'Concluído'
  | 'Finalizado'
  | 'Expirado'
  | 'Excluído';

export type Customer = {
  customer: { id?: string | null; name: string };
  picture?: string;
  customerName: string;
  endAt: string;
  expireAt?: string | null;
  id: string;
  inviteUrl?: string | null;
  projectName: string;
  remainingWeeks: number;
  status: CustomerStatus;
};

export type Response = {
  data: Customer[];
};

const getCustomers: ServiceMethod<Response> = (httpClient) => async () => {
  const { body } = await httpClient.get<Response>(
    `/api/v2/loyaltyProgram/relationship`
  );

  return body;
};

export default getCustomers;

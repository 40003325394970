import { type ServiceMethod } from '@/application/interfaces/service';

export type Params = {
  slug: string;
  type: 'contents' | 'products';
};

export type Body = {
  id: string | number;
};

export interface WishlistContents {
  id: string;
}

export interface WishlistProducts {
  id: number;
  sku_id?: string | null;
}

export type Response = {
  data: {
    id: string;
    name: string;
    slug: string;
    products?: WishlistProducts[];
    contents?: WishlistContents[];
  };
};

export type Data = Body & Params;

const addItemToWishlist: ServiceMethod<Response, Data> =
  (httpClient) => async (data, options) => {
    const { body } = await httpClient.post<Response, Body, Params, undefined>(
      `/api/v3/wishlists/{slug}/{type}`,
      {
        ...options,
        body: {
          id: data.id,
        },
        params: {
          slug: data.slug,
          type: data.type,
        },
      }
    );

    return body;
  };

export default addItemToWishlist;

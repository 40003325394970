import jscookie from 'js-cookie';

import { type ClientCookies, type ResponseCookieObj } from './types';
import { normalizeOptions } from './utils/normalize';

export const has: ClientCookies['has'] = (name) => Boolean(jscookie.get(name));

export const get: ClientCookies['get'] = (name) => ({
  name,
  value: jscookie.get(name),
});

export const set: ClientCookies['set'] = (options) => {
  jscookie.set(options.name, options.value, normalizeOptions(options));
};

export const remove: ClientCookies['remove'] = (name, domain) =>
  jscookie.remove(name, {
    domain,
  });

export const getMany: ClientCookies['getMany'] = <T extends string = string>(
  names: T[]
) =>
  names.reduce((arr, name) => {
    arr.push({
      name,
      value: jscookie.get(name),
    });

    return arr;
  }, [] as ResponseCookieObj<T>[]);

export const setMany: ClientCookies['setMany'] = (options) => {
  options.forEach((option) =>
    jscookie.set(option.name, option.value, normalizeOptions(option))
  );
};

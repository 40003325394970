import { type HttpClient } from '@/application/interfaces/http-client';

import {
  postUserContextByCoordinates,
  postUserContextByRegion,
  postUserContextByZipCode,
} from './post-user-context';

const UserContextService = (httpClient: HttpClient.Instance) => ({
  postUserContextByCoordinates: postUserContextByCoordinates(httpClient),
  postUserContextByRegion: postUserContextByRegion(httpClient),
  postUserContextByZipCode: postUserContextByZipCode(httpClient),
});

export default UserContextService;

import * as clientActions from './client';
import * as serverActions from './server';
import { createOptions, createRemoveOptions } from './utils/options';

export const cookies = {
  server: {
    has: serverActions.has,
    get: serverActions.get,
    set: serverActions.set,
    remove: serverActions.remove,
    getMany: serverActions.getMany,
    setMany: serverActions.setMany,
  },
  client: {
    has: clientActions.has,
    get: clientActions.get,
    set: clientActions.set,
    remove: clientActions.remove,
    getMany: clientActions.getMany,
    setMany: clientActions.setMany,
  },
  createOptions,
  createRemoveOptions,
};

import { type ServiceMethod } from '@/application/interfaces/service';
import { type ItemId } from '@/infra/services/catalog/model';

import { type Statistics } from '../model';

export type SearchParams = {
  productsLms: ItemId[];
};

export type Response = {
  data: Statistics[];
};

const getRatings: ServiceMethod<Response, SearchParams> =
  (httpClient) => async (data, options) => {
    const { productsLms } = data;

    const { body: response } = await httpClient.get<
      Response,
      undefined,
      SearchParams
    >('/api/v3/reviews/rating', {
      ...options,
      searchParams: {
        productsLms,
      },
    });

    return response;
  };

export default getRatings;

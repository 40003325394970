import { type ServiceMethod } from '@/application/interfaces/service';

export type Body = {
  override?: boolean;
  products: {
    id: number;
    quantity: number;
    skuId?: string;
  }[];
  type: 'delivery';
};

export type Response = {
  data: {
    shippings: {
      items: {
        productId: number;
      }[];
    }[];
    uniqueProductsCount: number;
  };
};

const addProductsToCart: ServiceMethod<Response, Body> =
  (httpClient) => async (data, options) => {
    const { products, override = false, type } = data;

    const { body: response } = await httpClient.post<Response, Body>(
      '/api/v1/cart',
      {
        ...options,
        body: {
          products,
          override,
          type,
        },
      }
    );

    return response;
  };

export default addProductsToCart;

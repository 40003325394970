import { type HttpClient } from '@/application/interfaces/http-client';

import getLoyaltyProfile from './get-loyalty-profile';
import getUserResources from './get-user-resources';

const UserService = (httpClient: HttpClient.Instance) => ({
  getUserResources: getUserResources(httpClient),
  getLoyaltyProfile: getLoyaltyProfile(httpClient),
});

export default UserService;

import { type ServiceMethod } from '@/application/interfaces/service';

import { type BaseParams, type BannerAds } from './model';

export type Body = BaseParams & {
  placements: {
    fullBanner: {
      quantity: number;
      types: ['banner'];
      size: '980_120';
    };
  };
};

export type Response = {
  fullBanner: BannerAds[];
};

const baseUrl = process.env.NEXT_PUBLIC_RETAIL_MEDIA_BASE_URL;
const publisherId = process.env.NEXT_PUBLIC_RETAIL_MEDIA_PUBLISHER_ID;

const getBannersAds: ServiceMethod<Response, BaseParams> =
  (httpClient) => async (params, options) => {
    const { context, session_id, user_id } = params;

    const { body: response } = await httpClient.post<Response, Body>(
      `${baseUrl}/v1/rma/${publisherId}`,
      {
        ...options,
        body: {
          context,
          session_id,
          user_id,
          placements: {
            fullBanner: {
              quantity: 1,
              size: '980_120',
              types: ['banner'],
            },
          },
        },
      }
    );

    return response;
  };

export default getBannersAds;
